import React, { useRef } from 'react';
import { LeftArrowIcon, RightArrowIcon } from '../../../Utils/Icons';

interface Report {
    name: string;
    href: string;
}

interface Phase {
    year: string;
    title: string;
    description: string;
    reports?: Report[];
}

const phases: Phase[] = [
    {
        year: "2016",
        title: "",
        description: "Determinar la asociación entre la contaminación del aire por los contaminantes PM10, PM2,5 y O3, y la enfermedad o muerte por Efectos agudos de la población de los 10 municipios que constituyen el Área Metropolitana del Valle de Aburrá entre el 2008 y el 2015.",
        reports: [
            {
                name: 'Calidad del Aire y sus Efectos en la Salud de la Población de los Diez Municipios del Valle de Aburrá 2008-2015',
                href: '/Documents/fase1.pdf',
            },
            {
                name: 'Revisión de Literatura Mundial Sobre calidad del Aire y Salud',
                href: '/Documents/fase2.pdf',
            },
            {
                name: 'Revisión de Literatura Mundial Sobre calidad del Aire y Salud en 5 Países de Iberoamérica',
                href: '/Documents/fase3.pdf',
            },
            {
                name: 'Mapping Review Grupos e Investigadores Nacional e Internacional',
                href: '/Documents/fase4.pdf',
            },
            {
                name: 'Tendencia de las Concentraciones de Contaminantes Criterio',
                href: '',
            },
            {
                name: 'Modelo AERMOD Dispersión Contaminantes en el Valle de Aburrá',
                href: '/Documents/fase6.pdf',
            },
            {
                name: 'Asociación Entre la Exposición de Corto Plazo a PM10 PM2.5 y O3 y Efectos Centinela de Salud en el Valle de Aburrá 2008 - 2015',
                href: '/Documents/fase7.pdf',
            },
            {
                name: 'Estimación de Parámetros de Modelos Aditivos Generalizados de la Asociación Entre Calidad del Aire y Morbilidad en los Diez Munipios del Valle de Aburrá 2008 - 2015 ',
                href: '/Documents/fase8.pdf',
            },
            {
                name: 'Gráficas Modelos Aditivos Generalizados (GAM) de la Asociación Entre Contaminantes Atmosféricos y Mortalidad en los Diez Municipios del Valle de Aburrá 2008 - 2015 ',
                href: '/Documents/fase9.pdf',
            },
            {
                name: 'Estimación de los Efectos en Morbilidad y Mortalidad Durante los Episodios Atmosféricos PM10, PM2.5 Medellín 2015',
                href: '/Documents/fase10.pdf',
            },
            {
                name: 'Modelo de Riesgos Proporcionales de COX y con Covariables Dependientes del Tiempo en la Asociación de Morbi-Mortalidad por Efectos Centinela de Tipo Crónica y las Concentraciones de Contaminantes PM10 y PM2.5 y O3 2008-2017',
                href: '/Documents/fase11.pdf',
            },
            {
                name: 'Análisis Integrado Asociación de Contaminantes Atmosféricos y Morbilidad por Causas Específicas: Meta - Análisis AMVA 2008 - 2016',
                href: '/Documents/fase12.pdf',
            },
        ]
    },
    {
        year: "2017",
        title: "",
        description: "Implementar un sistema de información integrado para la vigilancia en salud asociada a la contaminación atmosférica por los contaminantes PM10, PM2,5, O3, NO, NOx y NO2, y la gobernanza del aire en los municipios del Valle de Aburrá para el año 2016.",
        reports: [
            {
                name: 'Actualización del análisis de la relación morbilidad y mortalidad relacionados a la contaminación atmosférica en los diez municipios del Valle de Aburrá, 2016',
                href: '/Documents/Fase2_1.pdf',
                
            },
            {
                name: 'Revisión de los Modelos y Experiencia de Vigilancia en salud ambiental, con énfasis en el componente calidad del aire',
                href: '/Documents/Fase2_2.pdf',
                
            },
            {
                name: 'Contaminación Atmosférica y sus Efectos Sobre la Salud de los Habitantes del Valle de Aburrá 2008 - 2017 Análisis y Exposición de Corto y Largo Plazo',
                href: '/Documents/Fase2_3.pdf',
                
            },
        ]
    },
    {
        year: "2018 - 2019",
        title: "",
        description: "Apoyo a la implementación del PIGECA, mediante acciones que permitan fortalecer el proceso de gestión del conocimiento para el monitoreo de los efectos en salud relacionados a la contaminación atmosférica, evaluar los efectos en salud por exposición a largo plazo y estructurar el Sistema de Vigilancia en Salud Ambiental en el componente de efectos en salud relacionados a la calidad del aire, en el Área Metropolitana del Valle de Aburrá.",
        reports: [
            {
                name: 'Análisis Integrado Asociación de Contaminantes Atmosféricos y Morbilidad por Causas Específicas: Meta - Análisis AMVA 2008 - 2016',
                href: '/Documents/Fase3_1.pdf',
                
            },
            {
                name: 'Códigos - Modelos Case Crossover',
                href: '/Documents/Fase3_2.pdf',
                
            },
        ]
    },
    {
        year: "2021",
        title: "",
        description: "Aunar esfuerzos para la implementación del plan integral de gestión de la calidad del aire (PIGECA), mediante acciones que permitan la actualización del sistema de vigilancia de salud ambiental, en el componente de efectos en salud relacionados a la calidad del aire y generar apropiación del sistema de vigilancia por parte de los diez municipios y demás actores estratégicos.",
        reports: [
            {
                name: 'Metodología para la estimación de la mortalidad atribuible a la contaminación del aire por PM2.5 y ozono y los costos económicos relacionados, en los municipios del Valle de Aburra, a partir de las fuentes de información disponibles',
                href: '/Documents/Fase4_1.pdf',
            },
        ]
    },
    {
        year: "2022",
        title: "",
        description: "Aunar esfuerzos para la implementación del Plan de Gestión Integral de la Calidad del Aire (PIGECA) en su eje temático no 1 componente del Sistema de Vigilancia de Salud Ambiental, asociado a los efectos en salud y calidad del aire.",
    },
    {
        year: "2023",
        title: "",
        description: "Análisis de la morbilidad atribuible a PM2.5 y O3 y sus costos económicos y la automatización de la información de salud y ambiente necesaria para el Sistema de Vigilancia en Salud Ambiental en el marco del eje temático 1 del Plan integral de Gestión de la Calidad del Aire.",
    }
];

const Timeline: React.FC = () => {
    const scrollContainer = useRef<HTMLDivElement>(null);

    const scrollLeft = () => {
        if (scrollContainer.current) {
            scrollContainer.current.scrollBy({ left: -300, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (scrollContainer.current) {
            scrollContainer.current.scrollBy({ left: 300, behavior: 'smooth' });
        }
    };

    return (
        <div className="relative flex items-center justify-center p-4 ">
            <button
                onClick={scrollLeft}
                className="absolute left-0 z-10 p-2 bg-primary/50 rounded-full shadow-md hover:bg-primary"
                aria-label="Mover hacia la izquierda"
            >
                <LeftArrowIcon/>
            </button>
            
            <div ref={scrollContainer} className="flex no-scrollbar space-x-10 min-w-full min-h-full scrollbar-hidden overflow-hidden">
                {phases.map((phase, index) => (
                    <div key={index} className="relative flex flex-col items-center h-full">
                        <div className="flex flex-col items-center border border-primary rounded-lg bg-primary/80">
                            <span className="text-lg font-bold text-textColor mt-2">{phase.year}</span>
                            <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-300 w-64 md:w-80 text-center mt-2">
                                <h2 className="font-bold text-lg text-primary">{phase.title}</h2>
                                <p className="text-sm text-gray-600">{phase.description}</p>
                                {phase.reports && (
                                    <div className=' flex flex-col items-center'>
                                        <div className="p-4 w-64 md:w-80 ">
                                            <h3 className="text-center text-lg font-semibold text-primary mb-2">Reportes</h3>
                                            <div className="flex flex-col max-h-96 overflow-y-auto">
                                                {phase.reports.map((report, idx) => (
                                                    <a key={idx} href={report.href} download className="flex items-center text-sm text-blue-600 hover:underline mb-1">
                                                        <span className="w-2 h-2 bg-primary rounded-full mr-2"></span>
                                                        <span className="truncate w-full" title={report.name}>{report.name}</span>
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <button
                onClick={scrollRight}
                className="absolute right-0 z-10 p-2 bg-primary/50 rounded-full shadow-md hover:bg-primary"
                aria-label="Mover hacia la derecha"
            >
                <RightArrowIcon/>
            </button>
        </div>
    );
};

export default Timeline;