import React, { useState, useEffect } from 'react';
import { PdfIcon, ExcelIcon, LeftArrowIcon, RightArrowIcon } from '../../../Utils/Icons';

interface Report {
    name: string;
    href: string;
    type: string; // "P" para PDF, "E" para Excel
}

interface ReportListProps {
    reports: Report[];
    title?: string;
}

const useResponsiveItemsPerPage = () => {
    const [itemsPerPage, setItemsPerPage] = useState(9);

    useEffect(() => {
        const updateItemsPerPage = () => {
            if (window.innerWidth < 768) {
                setItemsPerPage(3);
            } else {
                setItemsPerPage(9);
            }
        };
        window.addEventListener('resize', updateItemsPerPage);
        updateItemsPerPage();
        return () => {
            window.removeEventListener('resize', updateItemsPerPage);
        };
    }, []);

    return itemsPerPage;
};

const ReportList: React.FC<ReportListProps> = ({ reports, title }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = useResponsiveItemsPerPage();

    const totalPages = Math.ceil(reports.length / itemsPerPage);

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrev = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const startIdx = currentPage * itemsPerPage;
    const currentReports = reports.slice(startIdx, startIdx + itemsPerPage);

    const getIcon = (type: string) => {
        if (type === "P") return <PdfIcon className="w-8 h-8" />;
        if (type === "E") return <ExcelIcon className="w-8 h-8" />;
        return null;
    };

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4 text-center">
                {title || 'Reportes'}
            </h2>
            <div className="relative">
                <button
                    onClick={handlePrev}
                    disabled={currentPage === 0}
                    className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-primary p-2 rounded-full disabled:opacity-50"
                    aria-label="Mover hacia la Izquierda"
                >
                    <LeftArrowIcon/>
                </button>
                <div
                    className={`grid ${
                        currentReports.length === 1
                            ? 'grid-cols-1 justify-center'
                            : currentReports.length === 2
                            ? 'justify-center grid-cols-1 sm:grid-cols-2'
                            : 'grid-cols-1 sm:grid-cols-3'
                    } gap-4`}
                >
                    {currentReports.map((report, index) => (
                        <a
                            key={index}
                            href={report.href}
                            download
                            className="p-4 border border-primary rounded-lg hover:bg-gris1 transition-colors grid grid-cols-[40px_auto] gap-4 items-center"
                        >
                            {getIcon(report.type)}
                            <span className="text-sm font-medium">{report.name}</span>
                        </a>
                    ))}
                </div>

                <button
                    onClick={handleNext}
                    disabled={currentPage === totalPages - 1}
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-primary p-2 rounded-full disabled:opacity-50"
                    aria-label="Mover hacia la derecha"
                >
                    <RightArrowIcon/>
                </button>
            </div>

            <div className="flex justify-center mt-4">
                {Array.from({ length: totalPages }, (_, index) => (
                    <div
                        key={index}
                        className={`h-3 w-3 mx-1 rounded-full ${
                            currentPage === index ? 'bg-primary' : 'bg-secondary'
                        }`}
                    />
                ))}
            </div>
        </div>
    );
};

export default ReportList;
